import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  connect() {
    const activeTab = window.location.hash.replace('##', '#');
    if (document.querySelector(".tab-pane")) {
      if (activeTab !== '') {
        document.querySelectorAll(".tab-pane").forEach((el) => {
          el.classList.remove('active')
        })
        document.querySelectorAll(".nav-link").forEach((el) => {
          el.classList.remove('active')
        })
        document.querySelector(activeTab).classList.add("active");
        document.querySelector(activeTab + '-tab').classList.add('active')
      }
      document.querySelectorAll(".nav-link").forEach((el) => {
        el.addEventListener('click', function (e) {
          //e.preventDefault()
          const baseUrl = window.location.href.split('#')[0];
          const hash = e.target.dataset.bsTarget; // including # at the beginning
          const url = baseUrl + hash;
          console.log(url)
          console.log(document.querySelector(hash))
       //   window.location.hash = hash
         Turbo.visit(url, {action: "replace"})
          //window.location.replace(url)
          // history.replaceState(null, '', url)
        })
      })
    }
  }
}
