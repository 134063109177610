import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invite"
export default class extends Controller {
  connect() {
    document.querySelectorAll('#invite_body').forEach(function (elem) {
      document.getElementById('invite-preview-text').innerText = elem.value;
      elem.addEventListener('keyup', (event) => {
        document.getElementById('invite-preview-text').innerText = elem.value;
      });
    });
  }
}
