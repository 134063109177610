export default class Bridge {

  constructor() {
    this.pendingMessages = new Set()
    this.ready = false
  }

  get adapter() {
    return this._adapter
  }

  set adapter(adapter) {
    this._adapter = adapter
    this.nativeActions = new NativeActions()
    this.ready = true
    this.sendPendintMessages()
  }

  send(message) {
    if (this.ready) {
      this.adapter.receive(message)
    } else {
      this.pendingMessages.add(message)
    }
  }

  sendPendintMessages() {
    this.pendingMessages.forEach((message) => {
      this.adapter.receive(message)
      this.pendingMessages.delete(message)
    })
  }
}

class NativeActions {
  click(id) {
    let element = document.querySelector(`[data-bridge-element-id='${id}']`)
    element.click()
  }
}