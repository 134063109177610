import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="charts"
export default class extends Controller {
  connect() {
    document.querySelectorAll('.chart').forEach(function (elem) {
      elem.innerHTML = '';
      let $chart = document.createElement('canvas');
      elem.appendChild($chart);
      let data = {
        labels: JSON.parse(elem.dataset.labels),
        datasets: [{
          label: elem.dataset.title,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          data: JSON.parse(elem.dataset.data),
        }]
      };
      let type = elem.dataset.type;
      if (type === 'dateline') type = 'line';
      let config = {
        type: type,
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              grace: '5%',
            }
          }
        },
      };
      new Chart(
        $chart,
        config
      );

    });
  }
}
