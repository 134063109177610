/**
 * Unobstrusive JavaScript
 */

/**
 * Handler for any element
 * - data-onclick
 * - data-onload
 */
let debounce = false;
let handleEvents = (e) => {
  setTimeout(function () {
    debounce = false;
  }, 50);
  if (debounce) {console.log('debounce ', e.type);return; }
  console.log("trigger ", e.type)
  debounce = true
  // document.removeEventListener('turbo:render', handleEvents);

  // data-onclick
  document.querySelectorAll('*[data-onclick]').forEach(function (elem) {
    elem.addEventListener('click', (event) => {
      event.preventDefault()
      eval(event.target.dataset.onclick)
    })
  })
  // data-onload
  document.querySelectorAll('*[data-onload]').forEach(function (elem) {
    if (elem.dataset.onload !== '') eval(elem.dataset.onload);
  })
}
['turbo:render', 'turbo:load'].forEach(function (evt) {
  document.addEventListener(evt, handleEvents)
});
