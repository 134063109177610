import {Controller} from "@hotwired/stimulus"
import bootstrap from "bootstrap/dist/js/bootstrap";

// Connects to data-controller="images"
export default class extends Controller {
  static targets = ["image", 'imagesUploadContainer', 'totalImages']

  connect() {
    console.log('images')
    const fileInputElement = this.imageTarget;
    const imagesElement = this.imagesUploadContainerTarget;
    const totalImagesElement = this.totalImagesTarget;
    const privacyLevels = JSON.parse(fileInputElement.dataset.privacy);

    // Listen to the change event on the <input> element
    fileInputElement.addEventListener('change', (event) => {
      // Get the selected image file
      const imageFiles = event.target.files;

      // Show the number of images selected
      totalImagesElement.innerText = imageFiles.length.toString();

      uploadForm(imagesElement, imageFiles, privacyLevels);
    });
  }
}


let imagesModal = () => {
  let modal = document.getElementById('images-modal');
  return new bootstrap.Modal(modal, {
    keyboard: false,
    backdrop: 'static'
  })
}
let privacyLevelSelect = (privacyLevels, index) => {
  let s = `<select class="col-sm-9" name="image[privacy][]" id="image_privacy_${index}">`;
  for (const privacyLevel of privacyLevels) {
    s = s + `<option value="${privacyLevel[1]}">${privacyLevel[0]}</option>`
  }
  return s + '</select>';
}
let imagePropertiesForm = (reader, imageFile, privacyLevels, index) => {
  return `
    <div class="col-6 mb-3">
        <img src='${reader.result}' class="w-100" alt="${imageFile.name}">
    </div>
    <div class="col-6 mb-3">
      <div class="row">
        <h5 class='image_name p-2'>${imageFile.name}</h5>
        <div class="row mb-2">
          <label class="form-label col-form-label col-sm-3 required">Titel</label>
          <input class="col-sm-9" type="text" name="image[title][]" id="image_title_${index}" required="required">
        </div>
        <div class="row mb-2">
          <label class="form-label col-form-label col-sm-3">&copy;</label>
          <input class="col-sm-9" type="text" name="image[copyright][]" id="image_copyright_${index}">
        </div>
        <div class="row mb-2">
          <label class="form-label col-form-label col-sm-3">Quelle</label>
          <input class="col-sm-9" type="text" name="image[source][]" id="image_source_${index}">
        </div>   
        <div class="row mb-2">
          <label class="form-label col-form-label col-sm-3">Wer darf das Bild sehen?</label>
          ${privacyLevelSelect(privacyLevels, index)}
        </div>
      </div>
    </div>`
}

let uploadForm = (targetElement, imageFiles, privacyLevels) => {
  targetElement.innerHTML = '';
  if (imageFiles.length > 0) {
    let $modal = imagesModal();
    $modal.show();
    let i = 0;
    // Loop through all the selected images
    // needs "const" for template interpolation
    for (const imageFile of imageFiles) {
      const reader = new FileReader();
      const index = i++;

      // Convert each image file to a string
      reader.readAsDataURL(imageFile);

      // FileReader will emit the load event when the data URL is ready
      // Access the string using reader.result inside the callback function
      reader.addEventListener('load', () => {
        targetElement.innerHTML += imagePropertiesForm(reader, imageFile, privacyLevels, index);
      });
    }
  }
}
