import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="location"
export default class extends Controller {
  connect() {
    /* Listen for zipcode changes and update city list */
    document.querySelector('#user_zipcode').addEventListener('change', function (el) {
      locationSelect(el)
    });
    document.querySelector('#user_zipcode').addEventListener('keyup', function (el) {
      if (el.target.value.length >= 5)
        locationSelect(el)
    });
  }
}

let locationSelect = (elem) => {
  let zipcode = elem.target.value;
  let frame = document.querySelector('turbo-frame#location-select')
  if (frame != null) {
    frame.src = '/my/profile/location_select?country=DE&zipcode=' + zipcode;
    frame.reload()
  }
}
