import { Controller } from "@hotwired/stimulus"
import bootstrap from "bootstrap/dist/js/bootstrap";

// Connects to data-controller="avatar"
export default class extends Controller {
  connect() {
    let image = document.getElementById('avatar-image');
    let input = document.getElementById('avatar-input');
    let modal = document.getElementById('avatar-modal');
    let $modal = new bootstrap.Modal(modal, {
      keyboard: false
    })
    let cropper;

    input.addEventListener('change', function (e) {
      let files = e.target.files;
      let done = function (url) {
        input.value = '';
        image.src = url;
        $modal.show();
      };
      let reader;
      let file;

      if (files && files.length > 0) {
        file = files[0];

        if (URL) {
          done(URL.createObjectURL(file));
        } else if (FileReader) {
          reader = new FileReader();
          reader.onload = function (e) {
            done(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
    });

    modal.addEventListener('shown.bs.modal', function () {
      cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 0,
      });
    });
    modal.addEventListener('hidden.bs.modal', function () {
      try {
        cropper.destroy();
        cropper = null;
      } catch (ex) {
        // ignore exception
      }
    });

    document.getElementById('avatar-crop').addEventListener('click', function () {
      $modal.hide();

      if (cropper) {
        let canvas = cropper.getCroppedCanvas({
          width: 256,
          height: 256,
        });

        canvas.toBlob(function (blob) {
          let fileName = 'avatar.jpg'
          let file = new File([blob], fileName, {type: "image/jpeg", lastModified: new Date().getTime()}, 'utf-8');
          let container = new DataTransfer();
          container.items.add(file);
          input.files = container.files;
          document.querySelector('#avatar-submit').click();
        });
      }
    });
  }
}
