import {
    Controller
} from "@hotwired/stimulus"

// Connects to data-controller="messages"
export default class extends Controller {
    initialize() {
        super.initialize();
        this.handlePopstate = this.handlePopstate.bind(this);
    }

    connect() {
        if (window.messageWatcher === undefined) {
            window.addEventListener("popstate", this.handlePopstate);
            window.messageWatcher = 'watched';
        }
    }

    handlePopstate(event) {
        // when back-button pressed manually perform visit
        Turbo.visit(window.location.href, {
            action: "replace"
        });
    }
}