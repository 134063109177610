import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="maps"
export default class extends Controller {
  static values = {
    init: String,
    wrapper: String,
    prefix: String,
    lat: String,
    lon: String,
    image: String,
    shadow: String
  }
  static targets = ['map']

  connect() {
    const prefix = this.prefixValue;
    const elem = document.getElementById('map-' + prefix);
    const lat = this.latValue;
    const lon = this.lonValue;
    const image = this.imageValue;
    const shadow = this.shadowValue;
    if (this.initValue === 'delay') {
      document.querySelector(this.wrapperValue).addEventListener('shown.bs.collapse', function () {
        initMap(elem, prefix, lat, lon, image, shadow);
      })
    } else {
      initMap(elem, prefix, lat, lon, image, shadow);
    }
  }
}

let initMap = (elem, prefix, lat, lon, imageUrl, shadowUrl) => {
  let map = L.map(elem).setView([lat, lon], 13);

  L.tileLayer('https://maps.rgimg.de/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors'
  }).addTo(map);

  setMarker(map, prefix, [lat, lon], false, imageUrl, shadowUrl)
}
