let rgIcon, rgMarker;

/**
 * Common map handling
 */

setMarker = (map, prefix, latlng, draggable, iconUrl, shadowUrl) => {
  if (rgMarker !== undefined) {
    map.removeLayer(rgMarker)
  }
  rgIcon = L.icon({
    iconUrl: iconUrl,
    iconSize: [17, 29],
    iconAnchor: [8, 22],
    popupAnchor: [-3, -76],
    shadowUrl: shadowUrl,
    shadowSize: [32, 29],
    shadowAnchor: [8, 22]
  });
  rgMarker = L.marker(latlng, {icon: rgIcon, draggable: draggable, autoPan: true});
  rgMarker.addTo(map);
  rgMarker.setLatLng(latlng);
  if (draggable) {
    rgMarker.on('moveend', function (e) {
      updateLatLng(prefix, e.target._latlng);
    });
    updateLatLng(prefix, latlng);
  }
}

updateLatLng = (prefix, latlng) => {
  if (latlng == 0) {
    document.querySelector('#' + prefix + '_latitude').value = '';
    document.querySelector('#' + prefix + '_longitude').value = '';
  } else {
    let l = L.latLng(latlng);
    document.querySelector('#' + prefix + '_latitude').value = l.lat;
    document.querySelector('#' + prefix + '_longitude').value = l.lng;
  }
}

useLatLng = (map, prefix, iconUrl, shadowUrl) => {
  let lat = document.querySelector('#' + prefix + '_latitude').value;
  let lon = document.querySelector('#' + prefix + '_longitude').value;
  if (lat !== '' && lon !== '') {
    setMarker(map, prefix, [parseFloat(lat), parseFloat(lon)], true, iconUrl, shadowUrl);
    map.setView([parseFloat(lat), parseFloat(lon)], 13);
    return true
  }
  return false
}

useAddress = (map, prefix, iconUrl, shadowUrl) => {
  let q = document.querySelector('#' + prefix + '_street').value + ', ' +
    document.querySelector('#' + prefix + '_zipcode').value + ' ' +
    document.querySelector('#' + prefix + '_city').value;
  let url = '/geodata/geocode?q=' + q;
  fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      if (json.result === 200) {
        document.querySelector('#' + prefix + '_latitude').value = json.lat;
        document.querySelector('#' + prefix + '_longitude').value = json.lon;
        setMarker(map, prefix, [parseFloat(json.lat), parseFloat(json.lon)], true, iconUrl, shadowUrl);
        map.setView([parseFloat(json.lat), parseFloat(json.lon)], 13);
      } else {
        map.setView([50.1, 8.68], 13);
        alert('Adresse nicht gefunden, bitte Marker von Hand setzen');
      }
    });
}

deleteMap = (map, prefix, elem) => {
  if (rgMarker !== undefined) {
    map.removeLayer(rgMarker)
  }
  updateLatLng(prefix, 0);
  elem.querySelector('.map-chooser-has-location').classList.add('d-none');
  elem.querySelector('.map-chooser-has-no-location').classList.remove('d-none');
}