import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    timeout: String,
    src: String,
  }
  static targets = ['countdown']

  connect() {
    if (this.timeoutValue !== "") {
      const endTime = Date.parse(this.timeoutValue);
      const timerId = setInterval(() => {
        const now = Date.now();
        const t = endTime - now;
        this.countdownTarget.textContent = this.formatTime(t);
        if (t <= 0) {
          clearInterval(timerId);
          this.element.src = this.srcValue;
        }
      }, 1000);
    }
  }

  formatTime(time) {
    if (time < 0) {
      time = 0
    }
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const dayText = days > 0 ? (days === 1 ? "1 Tag" : `${days} Tage`) : '';
    return `${dayText} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  pad(number) {
    return number.toString().padStart(2, '0');
  }
}