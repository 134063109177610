const beforeUnloadHandler = (event) => {
  if (!confirm('Es gibt ungespeicherte Änderungen. Seite verlassen?')) {
    event.preventDefault();
  } else {
    document.removeEventListener('turbo:before-visit', beforeUnloadHandler);
    window.onbeforeunload = undefined;
  }
};

const submitFormHandler = (event) => {
  document.removeEventListener('turbo:before-visit', beforeUnloadHandler);
  window.onbeforeunload = undefined;	
}

['turbo:render', 'turbo:load'].forEach((evt) => {
  document.addEventListener(evt, function (e) {
    let forms = document.querySelectorAll('.prevent-frustration');
    forms.forEach((form) => {
      form.addEventListener('submit', submitFormHandler)
      let inputs = form.querySelectorAll('input, textarea, select');
      inputs.forEach((input) => {
        input.addEventListener('input', () => {
          window.onbeforeunload = function() {
            return 'Es gibt ungespeicherte Änderungen. Seite verlassen?';
          }
          document.addEventListener('turbo:before-visit', beforeUnloadHandler);
        })
      })
    })
  })
})
