import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diff"
export default class extends Controller {
  connect() {
    document.querySelectorAll('.diff').forEach(function (elem) {
      showDiff(elem)
    })
  }
}



function showDiff(elem) {
  const elemBefore = elem.querySelector('.diff-before')
  const elemAfter = elem.querySelector('.diff-after')
  const elemOut = elem.querySelector('.diff-out')
  let o = diffString(escape(elemBefore.innerHTML), escape(elemAfter.innerHTML))
  o = o.replace(/\r/gm, '<br />')
  o = o.replace(/\t/gm, '&nbsp;&nbsp;&nbsp;&nbsp;')
  elemOut.innerHTML = o
}

function escape(s) {
  return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
}

function diffString(o, n) {
  o = o.replace(/\s+$/, '');
  n = n.replace(/\s+$/, '')
  let out = diff(o == '' ? [] : o.split(/\s+/), n == '' ? [] : n.split(/\s+/)), str = '', i, x = null, pre
  let oSpace = o.match(/\s+/g), nSpace = n.match(/\s+/g)
  if (oSpace == x)
    oSpace = ['\n'];
  else
    oSpace.push('\n')
  if (nSpace == x)
    nSpace = ['\n'];
  else
    nSpace.push('\n')
  if (out.n.length == 0) {
    for (i = 0; i < out.o.length; i++)
      str += '<del>' + escape(out.o[i]) + oSpace[i] + '</del>'
  } else {
    if (out.n[0].text == x) {
      for (n = 0; n < out.o.length && out.o[n].text == x; n++)
        str += '<del>' + escape(out.o[n]) + oSpace[n] + '</del>'
    }
    for (i = 0; i < out.n.length; i++) {
      if (out.n[i].text == x)
        str += '<ins>' + escape(out.n[i]) + nSpace[i] + '</ins>'
      else {
        pre = ''
        for (n = out.n[i].row + 1; n < out.o.length && out.o[n].text == x; n++) {
          pre += '<del>' + escape(out.o[n]) + oSpace[n] + '</del>'
        }
        str += " " + out.n[i].text + nSpace[i] + pre
      }
    }
  }
  return str
}

function diff(o, n) {
  let ns = {}, os = {}, i, x = null
  for (i = 0; i < n.length; i++) {
    if (ns[n[i]] == x)
      ns[n[i]] = {
        rows: [],
        o: x
      };
    ns[n[i]].rows.push(i)
  }
  for (i = 0; i < o.length; i++) {
    if (os[o[i]] == x)
      os[o[i]] = {
        rows: [],
        n: x
      };
    os[o[i]].rows.push(i)
  }
  for (i in ns) {
    if (ns[i].rows.length == 1 && typeof (os[i]) != 'undefined' && os[i].rows.length == 1) {
      n[ns[i].rows[0]] = {
        text: n[ns[i].rows[0]],
        row: os[i].rows[0]
      }
      o[os[i].rows[0]] = {
        text: o[os[i].rows[0]],
        row: ns[i].rows[0]
      }
    }
  }
  for (i = 0; i < n.length - 1; i++) {
    if (n[i].text !== x && n[i + 1].text == x && n[i].row + 1 < o.length && o[n[i].row + 1].text == x &&
      n[i + 1] == o[n[i].row + 1]) {
      n[i + 1] = {
        text: n[i + 1],
        row: n[i].row + 1
      }
      o[n[i].row + 1] = {
        text: o[n[i].row + 1],
        row: i + 1
      }
    }
  }
  for (i = n.length - 1; i > 0; i--) {
    if (n[i].text !== x && n[i - 1].text == x && n[i].row > 0 && o[n[i].row - 1].text == x &&
      n[i - 1] == o[n[i].row - 1]) {
      n[i - 1] = {
        text: n[i - 1],
        row: n[i].row - 1
      }
      o[n[i].row - 1] = {
        text: o[n[i].row - 1],
        row: i - 1
      }
    }
  }
  return {
    o: o,
    n: n
  }
}