import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="maps-editor"
export default class extends Controller {
  static values = {
    enabled: String,
    prefix: String,
    image: String,
    shadow: String
  }
  static targets = ['map', 'mapEditor']

  connect() {
    if (this.enabledValue === 'true') {
      this.addMap();
    }
  }

  addMap() {
    this.mapEditorTarget.querySelector('.map-chooser-has-no-location').classList.add('d-none');
    this.mapEditorTarget.querySelector('.map-chooser-has-location').classList.remove('d-none');
    if (this.map === undefined) {
      this.map = L.map(this.mapTarget);
    }
    L.tileLayer('https://maps.rgimg.de/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> Mitwirkende'
    }).addTo(this.map);
    console.log( "this.prefixValue",  this.prefixValue)
    console.log( "this.imageValue",  this.imageValue)
    this.map.on('click', (e) => {
      console.log( "this.prefixValue",  this.prefixValue)
      console.log( "this.imageValue",  this.imageValue)
      setMarker(this.map, this.prefixValue, e.latlng, true, this.imageValue, this.shadowValue);
    });
    if (!useLatLng(this.map, this.prefixValue, this.imageValue, this.shadowValue))
      useAddress(this.map, this.prefixValue, this.imageValue, this.shadowValue);
  }

  useAddress() {
    useAddress(this.map, this.prefixValue, this.imageValue, this.shadowValue);
  }

  deleteMap() {
    deleteMap(this.map, this.prefixValue, this.mapEditorTarget)
  }
}
