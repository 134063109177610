import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notes"
export default class extends Controller {
  connect() {
    document.querySelector('#internal-note').addEventListener('click', function (e) {
      let elNote = e.target.closest('.note');
      if (elNote != null) {
        let el = e.currentTarget;
        let elNoteForm = el.querySelector('.note-form');
        let elNoteCancel = el.querySelector('.note-cancel');
        let elNoteText = el.querySelector('.note-text');
        let value = elNoteText.value;
        // show form
        elNote.classList.add('d-none')
        elNoteForm.classList.remove('d-none');
        // init grow wrap
        let grower = elNoteText.closest('.grow-wrap');
        elNoteText.addEventListener("input", () => {
          grower.dataset.replicatedValue = elNoteText.value;
        });
        grower.dataset.replicatedValue = elNoteText.value;
        elNoteText.focus();
        // use .onclick to have only one event listener!
        elNoteCancel.onclick = function () {
          if (elNoteText.value == value || confirm('Änderungen verwerfen?')) {
            elNote.classList.remove('d-none');
            elNoteForm.classList.add('d-none');
            elNoteText.value = value;
          }
        }
      }
    })
  }
}
