import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="ai"
export default class extends Controller {
  static targets = ["aiText", 'convertButton', 'title', 'description', 'startAt', 'zipCode', 'city',
    'street', 'location', 'costs', 'url']

  connect() {
  }

  textToEvent(e) {
    e.preventDefault()
    this.convertButtonTarget.querySelector('.icon-text').innerText = 'Analyisere ...';
    this.convertButtonTarget.querySelector('.icon-normal').classList.add('d-none');
    this.convertButtonTarget.querySelector('.icon-wait').classList.remove('d-none');

    const formData = new FormData();
    const text = this.aiTextTarget.value.trim();
    const token = document.querySelector('meta[name="csrf-token"]').content;
    formData.append('text', text);

    fetch('/ai/text_to_event', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token
      },
      body: formData
    }).then(response => response.json())
      .then(data => {
        this.fillField(this.titleTarget, data.title);

        if (data.description !== '') {
          window.editors['event_description'].commands.setContent(data.description);
          document.querySelector('[data-content-element="#event_description"] > .ProseMirror').classList.add('bg-success-subtle');
        }
        if (data.start_datetime !== '') {
          const date = new Date(data.start_datetime);
          let pad = v => `0${v}`.slice(-2);
          let datestring = `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
          this.fillField(this.startAtTarget, datestring);
          this.startAtTarget._flatpickr.setDate(date);
        }

        this.fillField(this.zipCodeTarget, data.zipcode);
        this.fillField(this.cityTarget, data.city);
        this.fillField(this.streetTarget, data.street_no);
        this.fillField(this.locationTarget, data.location);
        this.fillField(this.costsTarget, data.cost);
        this.fillField(this.urlTarget, data.url);

        this.convertButtonTarget.querySelector('.icon-text').innerText = 'Event aus Text erstellen';
        this.convertButtonTarget.querySelector('.icon-normal').classList.remove('d-none');
        this.convertButtonTarget.querySelector('.icon-wait').classList.add('d-none');

        const target = this.convertButtonTarget;
        const elementPosition = target.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - 10;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      });

  }

  fillField(elem, text) {
    if (text !== '') {
      elem.value = text;
      elem.classList.add('bg-success-subtle')
    }
  }
}