// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import Bridge from "./bridge/bridge"
window.webBridge = new Bridge()

import "leaflet"
import Cropper from "cropperjs/dist/cropper.min"
window.Cropper = Cropper

import "./controllers"
import "./custom/beforeUnload"
import "./custom/dtsel"
import "./custom/flatpickr"
import "./custom/filepond"
import "./custom/rausgeher"
import "./custom/print"
import "./custom/membershiporders"
// import "./custom/messages"
import "./custom/maps"
import "./custom/tiptap"
import "./custom/ujs"

import "./fontawesome/fontawesome"
import "./fontawesome/solid.subset"
import "./fontawesome/regular.subset"
import "./fontawesome/duotone.subset"

import Rails from '@rails/ujs';

Rails.start();
