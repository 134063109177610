import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="reports"
export default class extends Controller {
  static targets = ["query", "previewForm", "previewQuery"]

  preview() {
    this.previewQueryTarget.value = this.queryTarget.value
    this.previewFormTarget.requestSubmit().requestSubmit()
  }
}
