import * as bootstrap from "bootstrap"

['turbo:render', 'turbo:load'].forEach(function (evt) {
  document.addEventListener(evt, function (e) {

    let popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {
        trigger: "hover",
        html: true,
        content: function () {
          return this.querySelector(".event-tooltip").innerHTML;
        },
        container: popoverTriggerEl,
      });
    });
  });
});

// Resize text areas  https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
['turbo:render', 'turbo:load'].forEach(function (evt) {
  document.addEventListener(evt, function (e) {
    let growers = document.querySelectorAll(".grow-wrap");
    growers.forEach((grower) => {
      const textarea = grower.querySelector("textarea");
      textarea.addEventListener("input", () => {
        grower.dataset.replicatedValue = textarea.value;
      });
      grower.dataset.replicatedValue = textarea.value;
    });
  });
});

import * as timeago from 'timeago.js';

const locale = function (number, index, totalSec) {
  // number: the time ago / time in number;
  // index: the index of array below;
  // totalSec: total seconds between date to be formatted and today's date;
  return [
    ['gerade eben', 'vor einer Weile'],
    ['vor %s Sekunden', 'in %s Sekunden'],
    ['vor 1 Minute', 'in 1 Minute'],
    ['vor %s Minuten', 'in %s Minuten'],
    ['vor 1 Stunde', 'in 1 Stunde'],
    ['vor %s Stunden', 'in %s Stunden'],
    ['vor 1 Tag', 'in 1 Tag'],
    ['vor %s Tagen', 'in %s Tagen'],
    ['vor 1 Woche', 'in 1 Woche'],
    ['vor %s Wochen', 'in %s Wochen'],
    ['vor 1 Monat', 'in 1 Monat'],
    ['vor %s Monaten', 'in %s Monaten'],
    ['vor 1 Jahr', 'in 1 Jahr'],
    ['vor %s Jahren', 'in %s Jahren'],
  ][index];
};
timeago.register('de_DE', locale);
['turbo:render', 'turbo:load'].forEach(function (evt) {
  document.addEventListener(evt, function (e) {
    let elms = document.querySelectorAll('.datetime');
    if (elms.length > 0) {
      timeago.render(elms, 'de_DE');
    }
  });
});

['turbo:render', 'turbo:load'].forEach(function (evt) {
  document.addEventListener(evt, function (e) {
    let elms;
    elms = document.querySelectorAll('input[type="date"]');
    elms.forEach((el) => {
      let instance = new dtsel.DTS(el, {
        direction: 'BOTTOM'
      });
    })
    elms = document.querySelectorAll('input[type="datetime-local"]');
    elms.forEach((el) => {
      let instance = new dtsel.DTS(el, {
        showTime: true,
        showSeconds: false,
        dateFormat: "dd.mm.yyyy",
        timeFormat: "HH:MM",
        months: [
          "Januar", "Februar", "März", "April", "Mai", "Juni",
          "Juli", "August", "September", "Oktober", "November", "Dezember"
        ],
        monthsShort: [
          "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sept", "Okt", "Nov", "Dez"
        ],
        weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        timeDescr: ["Std.:", "Min.:", "Sek.:"]
      });
    });
  });
});

import Tags from "bootstrap5-tags";

['turbo:render', 'turbo:load'].forEach(function (evt) {
  document.addEventListener(evt, function (e) {
    Tags.init('.tag-select');
    document.querySelectorAll('.tag-select').forEach(function (elem) {
      elem.addEventListener("change", function (ev) {
        let inst = Tags.getInstance(this);
        inst.getSelectedValues().forEach(function (value) {
          if (value.startsWith('ignore:')) {
            alert('Du kannst ' + value.replace('ignore:', '') + ' keine Nachricht schreiben, da das Mitglied dich auf die Ignorieren-Liste gesetzt hat.')
            inst.removeItem(value)
          }
        })
      });
    });
  });
});


['turbo:render', 'turbo:load'].forEach(function (evt) {
  document.addEventListener(evt, function (e) {
    document.querySelectorAll('.input-single-digit').forEach(function (elem) {
      // Select all content on click
      elem.addEventListener('focus', function (e) {
        e.target.setSelectionRange(0, e.target.value.length)
      })
      // Jump to next field if filled. On paste in first field split and distribute to all fields
      elem.addEventListener('input', function (e) {
        if (this.value.length == 1) {
          document.getElementById(e.target.dataset.nextfield).focus()
        }
        if (this.value.length > 1) {
          let v = this.value.replace(/\D/g, '');
          this.value = '';
          let nextField = '';
          for (let i = 0; i < Math.min(9, v.length); i++) {
            document.getElementById("code" + (i + 1)).value = v.charAt(i);
            nextField = document.getElementById("code" + (i + 1)).dataset.nextfield;
          }
          document.getElementById(nextField).focus()
        }
      })

    });
  });
});

/* show spinner on toggle boxes when calling remote ajax calls */
['turbo:render', 'turbo:load'].forEach(function (evt) {
  document.addEventListener(evt, function (e) {
    document.querySelectorAll('.form-switch').forEach(function (elem) {
      elem.addEventListener('ajax:before', (event) => {
        event.target.parentNode.classList.add('append-spinner');
      });
      elem.addEventListener('ajax:success', (event) => {
        let cl = event.target.parentNode.classList;
        cl.remove('append-spinner');
        cl.add('append-ok');
        setTimeout(function () {
          cl.remove('append-ok');
        }, 2000)
      });
      elem.addEventListener('ajax:error', (event) => {
        if (confirm('Es ist ein Fehler aufgetreten. Seite neu laden?')) {
          location.reload();
        }
      })
    });
  });
});