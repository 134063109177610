import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="recommend"
export default class extends Controller {
  connect() {
    document.querySelectorAll('.recommend-choice').forEach(function (elem) {
      elem.addEventListener('change', (event) => {
        document.getElementById('recommend-preview-text').innerText =
          event.target.parentNode.querySelector('label').innerText;
      });
    });
    document.querySelectorAll('.recommend-choice-own').forEach(function (elem) {
      elem.addEventListener('change', (event) => {
        document.getElementById('recommend-preview-text').innerText =
          event.target.parentNode.parentNode.querySelector('input[type=text]').value;
      });
    });
    document.querySelectorAll('#own_text').forEach(function (elem) {
      elem.addEventListener('keyup', (event) => {
        if (elem.value.length > 0) {
          document.getElementById('recommend-preview-text').innerText = elem.value;
          document.getElementById('text_own').checked = true;
        }
      });
    });
  }
}
